<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分充值审核</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
					<el-form-item style="display:flex;" label="提交时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-select  v-model="seachForm.status" clearable placeholder="请选择状态">
							<el-option v-for="item in enterOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.userName" placeholder="请输入企业用户" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="sumbitMore()">批量审核</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
                <el-table-column type="selection" width="55" :selectable='checkboxSelect' ></el-table-column>
				<el-table-column prop="createTime" label="提交时间" ></el-table-column>
				<el-table-column prop="userType" label="用户类型" >
                    <template slot-scope="scope">
                        <span >{{scope.row.userType == 0 ? '供应商' : '分销员'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="userName" label="用户名称" ></el-table-column>
				<el-table-column prop="drawIntegral" label="提现积分" ></el-table-column>
				<el-table-column prop="accountAmount" label="到账积分" ></el-table-column>
				<el-table-column prop="collectionAcc" label="收款方账号" ></el-table-column>
				<el-table-column prop="collectionFullname" label="收款方户名全称" width="150px"></el-table-column>
				<el-table-column prop="collectionBank" label="收款方银行" ></el-table-column>
				<el-table-column prop="accountAmount" label="预到账金额" ></el-table-column>
				<el-table-column prop="status" label="状态" >
                    <template slot-scope="scope">
                        <span >{{scope.row.status == 0 ? '未审核' : scope.row.status == 1 ? '提现审核通过' : scope.row.status == 2 ? '提现审核不通过' :  scope.row.status == 3 ? '转账成功' : '转账失败'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="checkTime" label="审核时间" ></el-table-column>
				<el-table-column prop="checkRemark" label="审核说明" ></el-table-column>
				<el-table-column prop="receipt" label="回执单" >
					<template style="display: flex;" slot-scope="scope">
                        <el-button v-if="scope.row.status == 3" @click.native.prevent="showPreview(scope.$index, data)" type="text" size="small">预览</el-button>
						<span v-else></span>
				    </template>
                </el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" :disabled="scope.row.status != 0" type="text" size="small">
							审核
						</el-button>
                        <el-button @click.native.prevent="registerClick(scope.$index, data)" :disabled="scope.row.status != 1" type="text" size="small">
							登记
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 单量审核 -->
		<el-dialog :visible.sync="dialogVisible" width="600px" :before-close="handleClose" title="审核">
			<el-form class="form_box" label-position='right' label-width="70px" size="small">
                <el-form-item label="状态：">
					<el-radio-group v-model="checkStatus">
						<el-radio label="1">审核通过</el-radio>
						<el-radio label="2">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：">
                    <el-input type="textarea" :rows="4" v-model="explain" placeholder="请输入审核说明" style="width: 100%"></el-input>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose()">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitForm()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

        <!-- 批量审核 -->
		<el-dialog :visible.sync="moreAudit" width="500px" :before-close="handleClose" title="批量审核">
			<el-form class="form_box" label-position='right' label-width="70px" size="small">
                <h3 style="text-align:center;">此操作为批量审核操作，请谨慎使用</h3>
                <el-form-item label="状态：" style="margin:30px 0;">
					<el-radio-group v-model="checkStatus">
						<el-radio label="1">审核通过</el-radio>
						<el-radio label="2">审核拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="说明：">
                    <el-input type="textarea" :rows="4" v-model="explain" placeholder="请输入审核说明" style="width: 100%"></el-input>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose()">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitFormMore()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

        <!-- 回执单预览 -->
        <el-dialog :visible.sync="previewShow" width="600px" title="回执单预览">
            <img width="60%" :src="previewUrl" alt="" style="margin:0 auto;display:block;">
        </el-dialog>

        <!-- 登记弹出层 -->
        <el-dialog :visible.sync="registerShow" width="500px" title="登记" :before-close="handleClose" >
			<el-form class="form_box"  label-position='right'   size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:70px;text-align:right;margin-right:10px;">状态：</p>
							<el-radio-group style="display:flex;align-items:center;"  v-model="registerStatus">
								<el-radio label="3">转账成功</el-radio>
								<el-radio label="4">转账失败</el-radio>
							</el-radio-group>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:70px;text-align:right;margin-right:10px;"><span style="color:red" v-if="registerStatus == 3">*</span>回执单：</p>
							<el-upload class="avatar-uploader" action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="registerImg.length > 0" :src="registerImg" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
							</el-upload>
						</div>
					</template>
				</el-form-item>
                <p class="form_tip"><span>*</span>企业会员转账成功时需上传回执单</p>
                <el-form-item size="large" class="targetButton" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose()">取消</el-button>
					<el-button type="primary"  @click.native.prevent="registerSubmit()">确定</el-button>
				</el-form-item>
            </el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		imageUrl
	} from '@/config/public';
	import {
		integraldrawList,integraldrawCheck,integraldrawRegister 
	} from "@/api/platform/index";
	export default {
		name: "splitaccountAuditList",
		data() {
			return {
                image_url:imageUrl,
                checkStatus:'1',
                seachForm:{
					userName:'',
					status:'',
					beginTime:'',
					endTime:'',
				},
                headers: {
					Authorization: this.$store.state.user.Token
				},
				enterOptions: [{label: '全部',value: ''},{label: '审核中',value: 0},{label: '审核通过',value: 1},{label: '审核拒绝',value: 2}],
				dialogVisible: false,
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                moreAudit:false,
				dateBetween:'',
                previewUrl:'',
				previewShow:false,
                id:'',
                explain:'',
                registerShow:false,
                registerImg:'',
                registerStatus:'3'
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integraldrawList({
                    beginTime: this.seachForm.beginTime,
                    endTime:this.seachForm.endTime,
					userName:this.seachForm.userName,
					status:this.seachForm.status,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			// 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.startTime = '';
                    this.endTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.startTime = datetime;
                this.endTime = datetime1;
            },
            
            // 审核完的状态不能选
            checkboxSelect (row, rowIndex) {
                if (row.status == 0) {
                    return true // 不禁用
                }else{
                    return false // 不禁用
                }
            },

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

            // 凭证弹出层
			showPreview(i,tab){
				this.previewUrl = tab[i].receipt != null ? tab[i].receipt : '';
				this.previewShow = true;
			},

             // 批量审核弹出层
            sumbitMore(){
                let that = this;
                if(that.$refs.elTable.selection.length > 0){
                    that.moreAudit = true;
                }else{
                    that.$message.warning('请先勾选要审核的内容');
                }
            },

			//关闭提醒
			handleClose(done) {
				this.dialogVisible = false;
				this.moreAudit = false;
				this.registerShow = false;
				this.checkStatus = '1';
				this.id = '';
				this.explain = '';
				this.registerImg = '';
			},

			//编辑按钮点击
			updataclick(i,tab){
				this.id = tab[i].id
				this.dialogVisible = true
			},

            // 单审核确定
			submitForm(){
                let that = this;
				if(that.dialogVisible){
					if(that.explain != null && that.explain.length > 0){
						integraldrawCheck({
							ids: that.id,
							status: Number(that.checkStatus),
							checkRemark: that.explain,
						}).then(res=>{
							if (res.code == 200) {
								that.$message.success('操作成功');
								
								that.dialogVisible = false
								that.checkStatus = '1';
								that.explain = '';
								that.id = '';
								that.page = 1
								that.list();
							} else {
								that.$message.error(res.msg);
							}
						}).catch(err => {
							that.$message.error(err.msg);
						})
					}else{
						that.$message.warning('审核说明不能为空');
					}
                }
			},

			// 批量审核确定
            submitFormMore(){
                let that = this;
                var arrIds = [];
                for(var i of that.$refs.elTable.selection){
                    arrIds.push(i.id);
                }
				if(that.explain != null && that.explain.length > 0){
					integraldrawCheck({
						ids: arrIds.join(','),
						status: Number(that.checkStatus),
						checkRemark: that.explain,
					}).then(res=>{
						if (res.code == 200) {
							that.$message.success('操作成功');
							
							that.moreAudit = false;
							that.checkStatus = '1';
							that.explain = '';
							that.page = 1
							that.list();
						} else {
							that.$message.error(res.msg);
						}
					}).catch(err => {
						that.$message.error(err.msg);
					})
				}else{
					that.$message.warning('审核说明不能为空');
				}
            },


            //图片上传
			handleAvatarSuccess(res, file) {
				if(res.code == 200){
                    this.registerImg = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},

            //图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},

            // 登记弹出层
            registerClick(i,tab){
                this.registerShow = true;
				this.id = tab[i].id;
            },

            // 登记回调
            registerSubmit(){
                let that = this;
                if(that.registerStatus == '3'){
                    if(that.registerImg != null && that.registerImg.length > 0){
                        integraldrawRegister({
                            id: that.id,
                            status: that.registerStatus,
                            receipt: that.registerImg
                        }).then(res=>{
                            if (res.code == 200) {
                                that.$message.success('操作成功');
                                
                                that.registerShow = false;
                                that.registerStatus = '3';
                                that.registerImg = '';
                                that.page = 1
                                that.list();
                            } else {
                                that.$message.error(res.msg);
                            }
                        }).catch(err => {
                            that.$message.error(err.msg);
                        })
                    }else{
                        that.$message.warning('转账成功需上传回执单');
                    }
                }else{
                    integraldrawRegister({
                        id: that.id,
                        status: that.registerStatus,
                        receipt: that.registerImg
                    }).then(res=>{
                        if (res.code == 200) {
                            that.$message.success('操作成功');
                            
                            that.registerShow = false;
                            that.registerStatus = '3';
                            that.registerImg = '';
                            that.page = 1
                            that.list();
                        } else {
                            that.$message.error(res.msg);
                        }
                    }).catch(err => {
                        that.$message.error(err.msg);
                    })
                }
            }
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.form_tip{
    margin-top: 10px;
    font-size: 14px;
    color: #333;
	margin-left: 70px;
}
.form_tip span{
    color: red;
}
.targetButton{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
}
</style>
